import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 70px;
  position: relative;
  bottom: ${({ subject }) => (subject === 'math' ? 0 : '2rem')};
  .desc {
    max-width: 390px;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }
  .main-title {
    font-weight: 900;
    font-family: 'Muli';
    font-size: 26px;
  }
  .main-desc {
    line-height: 28px;
    font-size: 1rem;
  }
`;

export const StyledLocked = styled.div`
  width: 564px;
  height: 320px;
  flex-shrink: 0;
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
  background: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  color: white;
  img {
    max-width: 100%;
    display: block;
  }
  p {
    margin-bottom: 0;
  }

  .title {
    font-size: 27px;
    font-weight: 900;
  }
  .second-desc {
    font-size: 18px;
    font-weight: 700;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.45);
  }
  .lock {
    max-width: 50px;
  }
`;
