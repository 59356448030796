import englishTrialCover from 'assets/img/english/trialCover.png';

export const trialContentConfig = {
  math: {
    title: 'Save Hours Struggling with Primary School Maths',
    desc: 'Learn 4x faster with our digital questions and animated tutorials in KooBits Math Premium. Go beyond the school version. No credit card needed.',
    secondDesc: 'Boost Your Math Today with a 7-day FREE Trial',
    videoUrl: 'https://www.youtube.com/embed/DZzaHWHl9u8',
    translationKey: 'mathTrial',
    backBtnColor: 'rgba(164, 140, 240, 0.6)',
    coverImage: null,
    showPremiumIcon: true,
    showNewBadge: false,
    showBoostBadge: true,
  },
  science: {
    title: 'Boost Your Science with KooBits 7-day FREE trial',
    desc: 'Get unlimited access to over 10,000 P1-6 digital MCQ and OEQ questions that are auto-marked with instant feedback. No credit card needed.',
    videoUrl: 'https://www.youtube.com/embed/053jj3j8hrQ',
    translationKey: 'scienceTrial',
    backBtnColor: 'rgba(111, 204, 7, 0.8)',
    coverImage: null,
    showPremiumIcon: false,
    showNewBadge: false,
    showBoostBadge: false,
  },
  english: {
    title: 'Be the first to try KooBits English! ',
    desc: 'More fun challenges, missions and skills to unlock.',
    videoUrl: null,
    translationKey: 'englishTrial',
    backBtnColor: 'rgba(254, 113, 32, 0.6)',
    coverImage: englishTrialCover,
    showPremiumIcon: false,
    showNewBadge: true,
    showBoostBadge: false,
  },
};
