import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { setShowPremiumSchoolToggleModal } from 'store/dashboard/studentDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import ArrowIcon from 'assets/img/premiumschooltoggle/arrow-left.svg';
import PremiumPlanImg from 'assets/img/premiumschooltoggle/premium-plan.png';
import SchoolPlanImg from 'assets/img/premiumschooltoggle/school-plan.png';
import Button from 'components/Shared/Button';
import { setCurrentProduct } from 'store/plan/planSlice';
import { updateUserPreferences } from 'store/userPreferences/userPreferencesSlice';
import { resetNotification } from 'store/notification/notificationSlice';
import { PRODUCT_TYPE } from 'constants/products';
import {
  Container,
  PlansContainer,
  ActionButtonsContainer,
} from './TogglePlanModalContent.styles';

const TogglePlanModalContent = ({ setShowLoader, setHasSeen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['switchPlan', 'common']);
  // Redux States
  const { plan, currentSubjectProduct } = useSelector((state) => state.plan);
  const { OTPEligibility } = useSelector((state) => state.trialSignup);

  // Event Handlers
  const cancelClickHandler = () => {
    dispatch(setShowPremiumSchoolToggleModal(false));
  };

  const plans =
    plan === 'school'
      ? t('switchPlan:plans.premium', 'Premium')
      : t('switchPlan:plans.school', 'School');

  const confirmClickHandler = async () => {
    const newProductType =
      plan === PRODUCT_TYPE.SCHOOL ? PRODUCT_TYPE.PREMIUM : PRODUCT_TYPE.SCHOOL;
    const findTheOtherPlan = currentSubjectProduct?.nonExpiredSubscriptionProducts.find(
      (p) => p.productType.toLowerCase().trim() === newProductType
    );
    if (findTheOtherPlan) {
      setCurrentProduct(findTheOtherPlan);
      dispatch(updateUserPreferences(findTheOtherPlan.productId));
      setShowLoader(true);
      setHasSeen(false);
      dispatch(resetNotification());
      dispatch(setShowPremiumSchoolToggleModal(false));
      history.push('/dashboard');
      return;
    }
    const subjectName = currentSubjectProduct.subject.toLowerCase().trim();
    dispatch(setShowPremiumSchoolToggleModal(false));
    history.push(
      OTPEligibility.isEligible
        ? `/trial/${subjectName}`
        : `/expired/${subjectName}`
    );
  };

  return (
    <Container plan={plan} data-cy="modal-content">
      <p className="title">{t('switchPlan:toggle.title', 'Switch Account')}</p>
      <p className="subtitle">
        <Trans i18nKey="switchPlan:toggle.subtitle" plans={plans}>
          You are going to switch to <span>{{ plans }} Account</span>
        </Trans>
      </p>
      <PlansContainer plan={plan} data-cy="plans">
        <span
          className={plan === 'school' ? '' : 'selected'}
          data-cy="option-school"
        >
          <img src={SchoolPlanImg} alt="School Plan" />
        </span>
        <img src={ArrowIcon} alt="arrow" className="arrow-icon" />
        <span
          className={plan === 'school' ? 'selected' : ''}
          data-cy="option-premium"
        >
          <img src={PremiumPlanImg} alt="Premium Plan" />
        </span>
      </PlansContainer>
      <ActionButtonsContainer>
        <Button
          variant="secondary"
          onClick={cancelClickHandler}
          dataCy="cancel-btn"
        >
          {t('common:cancel', 'Cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={confirmClickHandler}
          dataCy="confirm-btn"
        >
          {t('common:confirm', 'Confirm')}
        </Button>
      </ActionButtonsContainer>
    </Container>
  );
};

export default TogglePlanModalContent;
