import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import { getCurrentDateTimeInTimezone } from 'pages/Events/EventList';
import { kooChallengeIcons } from 'constants/dashboardv2';

import { rwd } from 'Theme';
import { StyledContainer } from './SuperheroChallengeCard.styles';
import ClosedOnSundayBadge from '../../../components/ClosedOnSundayBadge';

const CardImage = ({ isSMCEnabled, subject }) => {
  const isTablet = useMediaQuery({ maxWidth: rwd.tablet });
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });

  let imgSrc =
    kooChallengeIcons[subject][isSMCEnabled ? 'superheroSmall' : 'superhero'];
  if (isTablet) {
    imgSrc =
      kooChallengeIcons[subject][
        isSMCEnabled ? 'superheroTabletSmall' : 'superheroTablet'
      ];
  }
  if (isMobile) {
    imgSrc = kooChallengeIcons[subject].superheroTablet;
  }

  return <img src={imgSrc} alt="" className="bg" />;
};

const SuperheroChallengeCard = ({ isSMCEnabled }) => {
  const history = useHistory();
  const { t } = useTranslation(['dashboardCards']);
  const { timezone } = useSelector((state) => state.login);
  const { subject } = useSelector((state) => state.plan);
  const isSunday = getCurrentDateTimeInTimezone(timezone)?.isoWeekday() === 7;
  // Event Handler
  const clickHandler = () => {
    if (isSunday) return;
    history.push('/superhero');
  };
  return (
    <StyledContainer
      data-cy="superhero-challenge"
      onClick={clickHandler}
      isSunday={isSunday}
      isDisabled={isSunday}
      isSMCEnabled={isSMCEnabled}
      subject={subject}
    >
      <div>
        <CardImage isSMCEnabled={isSMCEnabled} subject={subject} />
        {isSunday && (
          <ClosedOnSundayBadge
            rightPosition={isSMCEnabled ? 0 : '1.3%'}
            bottomPosition="9%"
          />
        )}
        <p>{t('superHeroChallenge.name', 'Super Hero Challenge')}</p>
      </div>
    </StyledContainer>
  );
};

export default SuperheroChallengeCard;
