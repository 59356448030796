import { compareValues, compareDate } from 'helpers/compareValue';
import {
  productTypeHierarchy,
  subjectIDs,
  PRODUCT_TYPE,
  SUBJECTS,
} from 'constants/products';

export const getSubsData = (allNonExpiredSubscriptionProducts) => {
  let result = null;
  if (allNonExpiredSubscriptionProducts.length > 0) {
    for (let i = 0; i < allNonExpiredSubscriptionProducts.length; i += 1) {
      const productTypeLowerCase = allNonExpiredSubscriptionProducts[
        i
      ].productType
        .toLowerCase()
        .trim();
      if (
        productTypeLowerCase === PRODUCT_TYPE.PREMIUM ||
        productTypeLowerCase === PRODUCT_TYPE.STANDARD ||
        productTypeLowerCase === PRODUCT_TYPE.SCHOOL
      ) {
        result = allNonExpiredSubscriptionProducts[i];
        break;
      }
    }
  }
  return result?.subscriptionDetails;
};
export const parseProducts = ({
  cleanUserProducts,
  productFeatures,
  subscriptionPlans,
}) => {
  // Parse API Data
  const parsedProducts = [];
  cleanUserProducts.forEach((subscriptionProduct) => {
    const productIndex = parsedProducts.findIndex(
      (product) => product.id === subscriptionProduct.productParentId
    );
    const subscriptionProductHierarchy =
      productTypeHierarchy[
        subscriptionProduct.productType.toLowerCase().trim()
      ] ?? 99;
    const subscriptionPlanData = subscriptionPlans.find((subscription) => {
      return (
        subscription.Contract_Subscription_Id ===
          subscriptionProduct.subscriptionId &&
        subscription.ProductId === subscriptionProduct.productId
      );
    });
    const newSubscriptionProduct = {
      ...subscriptionProduct,
      hierarchy: subscriptionProductHierarchy,
      subject: subjectIDs[subscriptionProduct.subjectId],
      features: productFeatures[subscriptionProduct.productId] || [],
      subscriptionDetails: subscriptionPlanData ?? {},
    };
    // Product doesn't exist in array
    if (productIndex === -1) {
      parsedProducts.push({
        id: subscriptionProduct.productParentId,
        subjectId: subscriptionProduct.subjectId,
        subject: newSubscriptionProduct.subject,
        subscriptionProducts: [newSubscriptionProduct],
      });
    } else {
      parsedProducts[productIndex].subscriptionProducts.push(
        newSubscriptionProduct
      );
    }
  });

  const sortParsedProducts = parsedProducts
    .map((product) => {
      let hasExistPremium = false;
      let hasPremium = false;
      let hasSchool = false;
      let otpB2CActive = false;
      let schoolExisting = false;
      const expiredSubscriptionProducts = [];
      const nonExpiredSubscriptionProducts = [];
      product.subscriptionProducts.forEach((p) => {
        const lowerProductType = p.productType.toLowerCase().trim();
        if (lowerProductType === PRODUCT_TYPE.SCHOOL) {
          schoolExisting = true;
        }
        if (lowerProductType === PRODUCT_TYPE.PREMIUM && !p.isTrial) {
          hasPremium = true;
        }
        if (p.expired) {
          expiredSubscriptionProducts.push(p);
        } else {
          nonExpiredSubscriptionProducts.push(p);
        }
      });
      nonExpiredSubscriptionProducts.forEach((p) => {
        if (p.productType.toLowerCase().trim() === PRODUCT_TYPE.PREMIUM) {
          hasExistPremium = true;
        }
        if (p.productType.toLowerCase().trim() === PRODUCT_TYPE.SCHOOL) {
          hasSchool = true;
        }
        if (
          p.productType.toLowerCase().trim() !== PRODUCT_TYPE.SCHOOL &&
          p.subscriptionDetails.IsOTPTrial
        ) {
          otpB2CActive = true;
        }
      });
      return {
        ...product,
        subscriptionProducts: product.subscriptionProducts
          .slice()
          .sort(compareDate('endDate', 'desc'))
          .slice()
          .sort(compareValues('hierarchy', 'asc')),
        expiredSubscriptionProducts,
        nonExpiredSubscriptionProducts: nonExpiredSubscriptionProducts
          .slice()
          .sort(compareDate('endDate', 'desc'))
          .slice()
          .sort(compareValues('hierarchy', 'asc')),
        hasPremium,
        hasPremiumSchool: hasExistPremium && hasSchool,
        hasSchoolOnly: !hasExistPremium && hasSchool,
        hasExpiredSchool: schoolExisting && (!hasExistPremium || otpB2CActive),
      };
    })
    // math > science > english
    .slice()
    .sort((a, b) => a.subjectId - b.subjectId);
  return sortParsedProducts;
};
export const parseFeatureParameters = (parametersJSONString) => {
  let json;
  try {
    json = JSON.parse(parametersJSONString);
  } catch (err) {
    console.log(err.message);
  }
  return json;
};
export const currentSubscriptionProduct = ({
  userPreferences,
  allSubscriptionProducts,
  allNonExpiredSubscriptionProducts,
  dispatch,
  updateUserPreferences,
}) => {
  const currentUserProductPreferenceValue = userPreferences?.Value;
  if (!currentUserProductPreferenceValue) {
    // if has any active, should b2b first
    // else should b2c first
    const arr =
      allNonExpiredSubscriptionProducts.length > 0
        ? allNonExpiredSubscriptionProducts
            .slice()
            .sort(compareValues('hierarchy', 'desc'))
        : allSubscriptionProducts;
    const result = arr.find((indexProduct) => {
      if (
        indexProduct.productType.toLowerCase().trim() === PRODUCT_TYPE.SCHOOL
      ) {
        if (
          indexProduct.subject.toLowerCase().trim() ===
          SUBJECTS.MATH.toLowerCase().trim()
        ) {
          return true;
        }
        return true;
      }
      return false;
    });
    if (result) {
      dispatch(updateUserPreferences(result.productId));
      return result;
    }
  }

  const arr =
    allNonExpiredSubscriptionProducts.length > 0
      ? allNonExpiredSubscriptionProducts
      : allSubscriptionProducts;
  let result = arr.find(
    (indexProduct) =>
      allNonExpiredSubscriptionProducts.length > 0 &&
      indexProduct.productId === Number(currentUserProductPreferenceValue)
  );

  if (!result) {
    const indexProduct = arr[0];
    result = indexProduct;
    if (indexProduct) {
      dispatch(updateUserPreferences(indexProduct.productId));
    }
  }

  return result;
};
export const titleCase = (text) => {
  const output = text.replace(
    /(\w)(\w*)/g,
    (_, firstChar, rest) => firstChar + rest.toLowerCase()
  );
  return output;
};
export const parsedSubscriptions = (parsedProducts) => {
  const allNonExpiredSubscriptionProducts = [];
  const allSubscriptionProducts = [];
  parsedProducts
    .flatMap((p) => p.subscriptionProducts)
    .sort((a, b) => {
      // get subscription data sorting from premium, standard, school => for student details
      const aProductTypeLowerCase = a.productType.toLowerCase().trim();
      const bProductTypeLowerCase = b.productType.toLowerCase().trim();
      return (
        productTypeHierarchy[aProductTypeLowerCase] -
        productTypeHierarchy[bProductTypeLowerCase]
      );
    })
    .forEach((p) => {
      if (!p.expired) {
        allNonExpiredSubscriptionProducts.push(p);
      }
      allSubscriptionProducts.push(p);
    });
  return { allNonExpiredSubscriptionProducts, allSubscriptionProducts };
};
