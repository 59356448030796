import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AnimatedMiko from 'assets/img/loader/MIKO pump 400.gif';
import { ProgressBar } from 'react-bootstrap';
import { isNil } from 'ramda';
import { Container } from './Loader.styles';

const AutoFadeoutLoader = ({
  showLoader,
  setShowLoader,
  setHasSeen,
  redirectUrl,
}) => {
  const { t } = useTranslation(['dashboard']);
  const history = useHistory();
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    if (loadingPercentage < 100) {
      setTimeout(() => {
        setLoadingPercentage(loadingPercentage + 10);
      }, 250);
    } else if (loadingPercentage === 100) {
      setShowLoader(false);
      if (!isNil(redirectUrl)) history.replace(redirectUrl);
      setTimeout(() => {
        setHasSeen(true);
      }, 2000);
    }
  }, [history, loadingPercentage, redirectUrl, setHasSeen, setShowLoader]);
  return (
    <Container toFadeout={!showLoader}>
      <p className="welcome text">
        <Trans i18nKey="dashboard:loader.welcome">
          Welcome <span>to</span> KooBits
        </Trans>
      </p>
      <img src={AnimatedMiko} alt="Miko" height="150px" width="150px" />
      <ProgressBar variant="koobits" now={loadingPercentage} max={100} />
      <p className="loading text">
        {t('dashboard:loader.loading', 'Loading...')}
      </p>
    </Container>
  );
};
export default AutoFadeoutLoader;
