import React, { useState, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// slice and apis
import {
  getStudentDetails,
  setStudentDetails,
} from 'store/dashboard/studentDetailsSlice';
import { refreshToken } from 'store/login/loginSlice';
import { getCountryEligibleSubjects, getProducts } from 'store/plan/planSlice';
import {
  getCountries,
  getStudentParentDetail,
  getOTPEligibility,
} from 'store/trialSignup/trialSignupSlice';
import { fetchStudentDetails } from 'services/dashboard';
import { fetchWrapper } from 'services/login';
// slice and apis end
// images
import { ReactComponent as IconBack } from 'assets/img/trial/icon-back.svg';
import { ModalContentXButton, Button, ErrorModal } from 'components/Shared';
import iconCheckMark from './icon-check-mark.svg';
import iconInfoMark from './icon-info-mark.svg';
// images end
// component
import { ParentForm, OTPForm, StartingUp } from '..';
// component end
import {
  StepOneModal,
  StepTwoContainer,
  StepTwoModal,
  FinishedModal,
  StyledErrorResultModal,
} from './OTPModal.styles';

const INITIAL_STEP = 1;

const OTPModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation(['bundleTrial']);

  const { userID } = useSelector((state) => state.login);
  const { countries: countriesList, otpInfo } = useSelector(
    (state) => state.trialSignup
  );
  const { studentDetails } = useSelector((state) => state.studentDetails);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState({
    phoneNumber: '',
    phoneCode: '',
    iso: '',
  });
  const [errMsg, setErrMsg] = useState('');
  const [otp, setOtp] = useState('');
  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const [isActivating, setIsActivating] = useState(false);
  const [checkedConsent, setCheckedConsent] = useState(false);
  const [resultError, setResultError] = useState({
    isError: false,
    message: '',
  });

  const setDropdownValue = (value) => {
    setPhone({ ...phone, iso: value.iso, phoneCode: value.phoneCode });
  };

  const reloadHandler = () => {
    dispatch(getStudentDetails());
  };
  const closeModalAndRedirect = () => {
    // Close modal & redirect to dashboard page
    setIsActivating(false);
    setShowModal(false);
    history.push('/dashboard');
  };

  const handleActivation = async () => {
    setIsActivating(true);

    try {
      await dispatch(refreshToken({}));
      const studentDetail = await fetchWrapper(fetchStudentDetails);
      dispatch(setStudentDetails(studentDetail.Result));
      dispatch(getCountryEligibleSubjects(studentDetail.Result.CountryId));
      dispatch(getOTPEligibility());
      dispatch(getProducts());
    } catch (err) {
      console.log(err.message);
    } finally {
      closeModalAndRedirect();
    }
  };
  const handleCloseModal = () => {
    if (currentStep === 4 && !resultError.isError) {
      handleActivation();
      return;
    }
    setShowModal(false);
    setCurrentStep(1);
    setResultError({
      isError: false,
      message: '',
    });
  };
  useEffect(() => {
    dispatch(getCountries());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (userID) {
      dispatch(getStudentParentDetail(userID));
    }
  }, [userID, dispatch]);
  useEffect(() => {
    if (otpInfo?.parentUserId && countriesList?.data) {
      const { parentUserName, emailAddress, mobileNumber, mobileCountryCode } =
        otpInfo;
      setName(parentUserName || '');
      setEmail(emailAddress);
      const findCountry = countriesList.data.find(
        (c) => c.phoneCode === mobileCountryCode
      );
      if (!phone.phoneNumber && mobileCountryCode && mobileNumber) {
        setPhone({
          phoneNumber: mobileNumber,
          phoneCode: mobileCountryCode,
          iso: findCountry?.iso,
        });
      }
    } else if (studentDetails) {
      const findCountry = countriesList.data.find(
        (c) => c.trialCountryId === studentDetails?.CountryId
      );
      if (findCountry) {
        setPhone({
          phoneNumber: '',
          phoneCode: findCountry.phoneCode,
          iso: findCountry.iso,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpInfo, countriesList, studentDetails]);
  const containerMinWidth = useCallback(() => {
    if (currentStep === 3) {
      return '363px';
    }
    if (resultError.isError) {
      return '414px';
    }
    return '550px';
  }, [currentStep, resultError.isError]);
  return (
    <>
      <div>
        <ModalContentXButton
          showModal={showModal}
          closeModal={handleCloseModal}
          backdrop="static"
          showClosedButton={currentStep !== 3}
          borderRadius={
            currentStep === 3 || resultError.isError ? '17px' : '5px'
          }
          containerMinWidth={containerMinWidth()}
        >
          {currentStep === 1 && (
            <StepOneModal>
              <h4 className="modal-title">
                {t(
                  `bundleTrial:stepOne.title`,
                  `Parents Consent to Activate KooBits 7-day Trial`
                )}
              </h4>
              <p>
                {t(
                  `bundleTrial:stepOne.desc`,
                  `Please pass this section to your parents`
                )}
              </p>
              <ParentForm
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                phone={phone}
                setPhone={setPhone}
                checkedConsent={checkedConsent}
                setCheckedConsent={setCheckedConsent}
                setCurrentStep={setCurrentStep}
                errMsg={errMsg}
                setErrMsg={setErrMsg}
                reloadHandler={reloadHandler}
                setDropdownValue={setDropdownValue}
              />
            </StepOneModal>
          )}
          {currentStep === 2 && (
            <StepTwoModal>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
              <div className="back-button" onClick={() => setCurrentStep(1)}>
                <IconBack />
              </div>
              <StepTwoContainer>
                <h4 className="otp-title">
                  {t(`bundleTrial:stepTwo.title`, 'OTP Verification')}
                </h4>
                <p className="otp-desc">
                  {t(
                    `bundleTrial:stepTwo.desc`,
                    `Enter the OTP sent to your phone`
                  )}
                </p>
                <OTPForm
                  otp={otp}
                  setOtp={setOtp}
                  setCurrentStep={setCurrentStep}
                />
              </StepTwoContainer>
            </StepTwoModal>
          )}
          {currentStep === 3 && (
            <StartingUp
              setCurrentStep={setCurrentStep}
              setResultError={setResultError}
            />
          )}
          {currentStep === 4 &&
            (resultError.isError ? (
              <StyledErrorResultModal>
                <img
                  className="info-mark"
                  src={iconInfoMark}
                  alt="infoMark"
                  height="62px"
                  width="62px"
                />
                <h4 className="error-title">
                  {t('bundleTrial:errorResult.title', 'Oops!')}
                </h4>
                <p className="error-desc">
                  {t(
                    `bundleTrial:errorResult.desc`,
                    `7-day Free Trial was not activated successfully.`
                  )}
                </p>
                <p className="error-desc">
                  <Trans t={t} i18nKey="bundleTrial:errorResult.desc2">
                    Please
                    <a href="mailto:support@koobits.com"> Contact Us </a>
                    for assistance.
                  </Trans>
                </p>
              </StyledErrorResultModal>
            ) : (
              <FinishedModal>
                <img
                  className="check-mark"
                  src={iconCheckMark}
                  alt="science-checkmark"
                  height="80px"
                  width="80px"
                />
                <h4 className="finished-title">
                  {t('bundleTrial:finished.title', 'Congratulations!')}
                </h4>
                <p className="finished-description">
                  {t(
                    `bundleTrial:finished.desc`,
                    `Your KooBits 7-day Free Trial has been activated successfully!`
                  )}
                </p>
                <Button
                  dataCy="activate-button"
                  className="activate-button"
                  variant="primary"
                  width="300px"
                  onClick={handleActivation}
                  isDisabled={isActivating}
                >
                  {t('bundleTrial:finished.activate', 'Activate Now')}
                </Button>
              </FinishedModal>
            ))}
        </ModalContentXButton>
      </div>
      {!countriesList.isLoading && countriesList.isErr && (
        <ErrorModal
          errorMessage={countriesList.isErr}
          // reloadAction={
          //   countriesList.isErr ? handleGetCountries : getUserCountry
          // }
        />
      )}
    </>
  );
};

export default OTPModal;
