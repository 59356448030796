import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWrapper } from 'services/login';
import { handleResponseV2 } from 'helpers/handleAPIResponse';
import { setStudentStateFromProduct } from 'store/dashboard/studentDetailsSlice';
import { updateUserPreferences } from 'store/userPreferences/userPreferencesSlice';
import {
  fetchUserProducts,
  fetchFeaturesBySubscriptionProductID,
  fetchGlobalFeatureConfig,
  fetchUserActiveSubscriptions,
} from 'services/products';
import { fetchCountryEligibleSubjects } from 'services/trialSignup';
import { fetchPrices } from 'services/payment';
import { clone, isNil } from 'ramda';
import moment from 'moment-timezone';
import { getCurrentDateTimeInTimezone } from 'pages/Events/EventList';
import { PRODUCT_TYPE, SUBJECTS, subjectIDs } from 'constants/products';
import {
  getSubsData,
  parseProducts,
  parseFeatureParameters,
  currentSubscriptionProduct,
  titleCase,
  parsedSubscriptions,
} from './helper';
// Redux Thunks
export const getCountryEligibleSubjects = createAsyncThunk(
  'plan/getCountryEligibleSubjects',
  async (params) => {
    try {
      const res = await fetchWrapper(fetchCountryEligibleSubjects, params);
      return handleResponseV2(res);
    } catch (error) {
      throw new Error(error?.message ?? 'get country eligible subjects failed');
    }
  }
);
export const getProducts = createAsyncThunk(
  'plan/getProducts',
  async (_, { dispatch, getState }) => {
    // Get state from store
    const { studentDetails, userPreferences, login } = getState();
    const { timezone } = login;
    const subscriptionPlans =
      studentDetails?.studentDetails?.Subscriptions ?? [];
    try {
      const userProducts = await fetchWrapper(fetchUserProducts);

      const currentDateTimeMoment = getCurrentDateTimeInTimezone(timezone);
      const cleanUserProducts = userProducts
        .filter(
          (subscriptionProduct) =>
            !isNil(subscriptionProduct.productParentId) &&
            !isNil(subjectIDs[subscriptionProduct.subjectId])
        )
        .map((subscriptionProduct) => {
          return {
            ...subscriptionProduct,
            expired:
              currentDateTimeMoment.isBefore(
                moment.tz(subscriptionProduct.endDate, timezone)
              ) === false,
          };
        });
      const nonExpiredUserProducts = cleanUserProducts.filter(
        (p) => !p.expired
      );
      const productFeatures = await nonExpiredUserProducts.reduce(
        async (previousFeatures, subProduct) => {
          const collection = await previousFeatures;
          const subProductFeatures = await fetchWrapper(
            fetchFeaturesBySubscriptionProductID,
            subProduct.productId
          );
          const parsedSubProductFeatures = subProductFeatures.map(
            (subProductFeature) => {
              const parsedFeatureParameters = parseFeatureParameters(
                subProductFeature.parameters
              );
              return {
                ...subProductFeature,
                parameters: isNil(parsedFeatureParameters)
                  ? {}
                  : parsedFeatureParameters,
              };
            }
          );
          return {
            ...collection,
            [subProduct.productId]: parsedSubProductFeatures,
          };
        },
        Promise.resolve({})
      );
      // Parse API Data
      const parsedProducts = parseProducts({
        cleanUserProducts,
        productFeatures,
        subscriptionPlans,
      });
      const { allNonExpiredSubscriptionProducts, allSubscriptionProducts } =
        parsedSubscriptions(parsedProducts);

      const currentProduct = currentSubscriptionProduct({
        userPreferences: userPreferences.preferences,
        allSubscriptionProducts,
        allNonExpiredSubscriptionProducts,
        dispatch,
        updateUserPreferences,
      });

      const subsData = getSubsData(allNonExpiredSubscriptionProducts);
      const currentSubjectProduct = parsedProducts.find(
        (p) => p.subjectId === currentProduct.subjectId
      );

      if (subsData) {
        dispatch(
          setStudentStateFromProduct({
            country: titleCase(subsData.CountryName),
            // should remove countryCode because it will be moved to the student details root and called ISOCode3 and ISOCode2
            countryCode: subsData.ISOCode3,
            groupId: subsData.GroupId,
          })
        );
      }

      return {
        userProducts,
        currentProduct,
        parsedProducts,
        currentSubjectProduct,
        allNonExpiredSubscriptionProducts,
      };
    } catch (error) {
      throw new Error(error?.message ?? 'Get products failed');
    }
  }
);

export const getGlobalFeatures = createAsyncThunk(
  'plan/getGlobalFeatures',
  async () => {
    try {
      const res = await fetchGlobalFeatureConfig();
      return res;
    } catch (error) {
      throw new Error(error?.message ?? 'Get global features failed');
    }
  }
);

export const getActiveSubscriptions = createAsyncThunk(
  'plan/getActiveSubscriptions',
  async (_, { getState }) => {
    try {
      const { userID } = getState().login;
      const res = await fetchWrapper(fetchUserActiveSubscriptions, {
        userID,
      });
      return res.data;
    } catch (error) {
      throw new Error(error?.message ?? 'Get active subscriptions failed');
    }
  }
);

export const getSubjectBundle = createAsyncThunk(
  'plan/getSubjectBundle',
  async (userId) => {
    try {
      const res = await fetchWrapper(fetchPrices, userId);
      return res.data;
    } catch (error) {
      throw new Error(error?.message ?? 'Get subject bundle failed');
    }
  }
);

const initialState = {
  plan: PRODUCT_TYPE.SCHOOL,
  subject: SUBJECTS.MATH.toLowerCase(),
  currentProduct: null,
  currentSubjectProduct: null,
  allNonExpiredSubscriptionProducts: null,
  isLoading: false,
  // don't touch allExpired by use reducers
  allExpired: false,
  userProducts: null,
  products: null,
  getProductsError: null,
  // Takes precedence over product level feature config from backend
  globalFeatureConfig: {},
  allFeatures: null,
  prevProduct: null,
  isRedirect: true,
  activeSubscriptions: {
    isLoading: false,
    error: null,
    data: null,
  },
  countryEligibility: {
    isLoading: false,
    error: null,
    data: null,
  },
  subjectBundles: {
    isLoading: false,
    error: null,
    data: null,
  },
};

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setCurrentProduct: (state, action) => {
      const currentProduct = clone(state.currentProduct);
      const findSubjectProduct = state.products.find(
        (p) => p.subjectId === action.payload?.subjectId
      );
      state.prevProduct = currentProduct;
      state.currentProduct = action.payload;
      state.currentSubjectProduct = findSubjectProduct;
      state.allFeatures = action.payload?.features;
      state.plan = action.payload?.productType.toLowerCase().trim();
      state.subject = action.payload?.subject.toLowerCase().trim();
    },
    setIsRedirect: (state, action) => {
      state.isRedirect = action.payload;
    },
  },
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.isLoading = true;
      state.userProducts = null;
      state.getProductsError = null;
    },
    [getProducts.fulfilled]: (state, action) => {
      const {
        currentProduct,
        userProducts,
        parsedProducts,
        currentSubjectProduct,
        allNonExpiredSubscriptionProducts,
      } = action.payload;

      state.isLoading = false;
      state.userProducts = userProducts;
      state.currentProduct = currentProduct;
      state.allFeatures = currentProduct?.features;
      state.products = parsedProducts;
      state.currentSubjectProduct = currentSubjectProduct;
      state.allNonExpiredSubscriptionProducts =
        allNonExpiredSubscriptionProducts;
      state.allExpired = userProducts.every((product) => product.expired);
      if (currentProduct) {
        state.plan = currentProduct.productType.toLowerCase().trim();
        state.subject = currentProduct.subject.toLowerCase().trim();
      } else {
        const findSubscriptionProducts = parsedProducts.filter(
          (p) => p.subscriptionProducts.length > 0
        );
        state.plan =
          findSubscriptionProducts.length > 0
            ? findSubscriptionProducts[0].subscriptionProducts[0].productType
                .toLowerCase()
                .trim()
            : PRODUCT_TYPE.PREMIUM;
        state.subject = parsedProducts[0]
          ? parsedProducts[0].subject.toLowerCase()
          : SUBJECTS.MATH.toLowerCase();
      }
    },
    [getProducts.rejected]: (state, action) => {
      state.isLoading = false;
      state.getProductsError = action.error.message;
    },
    [getGlobalFeatures.pending]: () => {},
    [getGlobalFeatures.fulfilled]: (state, action) => {
      state.globalFeatureConfig = action.payload;
    },
    [getGlobalFeatures.rejected]: () => {},
    [getActiveSubscriptions.pending]: (state) => {
      state.activeSubscriptions.isLoading = true;
      state.activeSubscriptions.error = null;
      state.activeSubscriptions.data = null;
    },
    [getActiveSubscriptions.fulfilled]: (state, action) => {
      state.activeSubscriptions.isLoading = false;
      state.activeSubscriptions.data = action.payload;
    },
    [getActiveSubscriptions.rejected]: (state, action) => {
      state.activeSubscriptions.isLoading = false;
      state.activeSubscriptions.error = action.error.message;
    },
    [getCountryEligibleSubjects.pending]: (state) => {
      state.countryEligibility.isLoading = true;
      state.countryEligibility.error = null;
      state.countryEligibility.data = null;
    },
    [getCountryEligibleSubjects.fulfilled]: (state, action) => {
      state.countryEligibility.isLoading = false;
      state.countryEligibility.data = {
        ...action.payload,
        eligibleSubjects: action.payload.eligibleSubjects.map((subjectId) => ({
          subjectId,
          subject: subjectIDs[subjectId],
        })),
      };
    },
    [getCountryEligibleSubjects.rejected]: (state, action) => {
      state.countryEligibility.isLoading = false;
      state.countryEligibility.error = action.error.message;
    },
    [getSubjectBundle.pending]: (state) => {
      state.subjectBundles.isLoading = true;
      state.subjectBundles.error = null;
      state.subjectBundles.data = null;
    },
    [getSubjectBundle.fulfilled]: (state, action) => {
      state.subjectBundles.isLoading = false;
      state.subjectBundles.data = action.payload;
    },
    [getSubjectBundle.rejected]: (state, action) => {
      state.subjectBundles.isLoading = false;
      state.subjectBundles.error = action.error.message;
      state.subjectBundles.data = null;
    },
  },
});

const { actions, reducer } = planSlice;
export const { setCurrentProduct, setIsRedirect } = actions;
export default reducer;
