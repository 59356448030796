import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import ReactPlayer from 'react-player/youtube';

import { setCurrentProduct } from 'store/plan/planSlice';

import Header from 'components/Header';
import { Button, BackButton, TrialSelector } from 'components/Shared';
import { SG_COUNTRY_ID } from 'constants/products';
import { CountDown } from '../components';
import { SELLING_POINT } from './constants';

import { StyledButtonContainer, StyledTrialPopupModal } from '../index.styles';
import { Page, StyledContainer } from './BuyEnglish.styles';

const BuyEnglish = () => {
  const { t } = useTranslation(['englishTrial', 'common']);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const {
    prevProduct,
    currentProduct,
    isLoading: planIsLoading,
    products,
  } = useSelector((state) => state.plan);
  const { isLoading, studentDetails } = useSelector(
    (state) => state.studentDetails
  );

  const isSGUser = studentDetails?.CountryId === SG_COUNTRY_ID;

  const allLoading = isLoading || planIsLoading;

  const backHandler = () => {
    if (!isNil(prevProduct)) {
      dispatch(setCurrentProduct(prevProduct));
      history.push(`/dashboard`);
      return;
    }
    if (currentProduct && currentProduct.expired) {
      history.push(`/trial/${currentProduct.subject.toLowerCase().trim()}`);
      return;
    }
    history.push(`/dashboard`);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    !allLoading &&
    products && (
      <Page>
        <div>
          <StyledTrialPopupModal
            show={showModal}
            onHide={closeModal}
            centered
            backdrop="static"
            size="xl"
          >
            <TrialSelector
              onClose={closeModal}
              userId={studentDetails?.UserId}
              campaignCode="ExpirePage"
            />
          </StyledTrialPopupModal>
        </div>
        <Header toggleMenu={false} toggleAccessMenu={false} />
        <StyledButtonContainer>
          <BackButton
            btnBgColor="rgba(254, 113, 32, 0.6)"
            padding="0.4rem 1rem"
            backText={t('common:back', 'Back')}
            onClick={backHandler}
          />
        </StyledButtonContainer>
        <StyledContainer>
          <div className="desc">
            <div className="new">{t('common:new', 'NEW!')}</div>
            <h3 className="main-title">
              {t(
                `englishTrial:buyEnglish.title`,
                `Start your English Adventure with KooBits!`
              )}
            </h3>
            <div className="main-desc">
              {SELLING_POINT.filter((item) => !item.isSgOnly).map((item) => (
                <p key={item.key}>
                  {t(`englishTrial:buyEnglish.${item.key}`, item.text)}
                </p>
              ))}
            </div>
            {isSGUser && (
              <div className="main-desc">
                {SELLING_POINT.filter((item) => item.isSgOnly).map((item) => (
                  <p key={item.key} className="highlight">
                    {t(`englishTrial:buyEnglish.${item.key}`, item.text)}
                  </p>
                ))}
              </div>
            )}
            <div className="action-container">
              <Button
                dataCy="try-button"
                variant="primary"
                fontWeight="700"
                fontSize="1.5rem"
                padding="0.7rem 1.7rem"
                onClick={() => setShowModal(true)}
              >
                {t('englishTrial:buyEnglish.btn', 'Get English NOW')}
              </Button>
              <CountDown />
            </div>
          </div>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=ZSXYL8N4kVI"
            loop={false}
            config={{
              youtube: {
                playerVars: {
                  rel: 0,
                  autoplay: 1,
                },
              },
            }}
            controls
          />
        </StyledContainer>
      </Page>
    )
  );
};

export default BuyEnglish;
