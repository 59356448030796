import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-family: Muli;
  p {
    margin-bottom: 0;
  }
`;

export const ChildDetails = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 1rem 1.5rem;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 3px 3px rgb(0 0 0 / 16%);
  }
  .full-name {
    font-size: 1.125rem;
    font-weight: 700;
    color: #000000;
  }
  .login-id {
    color: #606060;
    font-size: 0.6875rem;
  }
`;

export const SubscriptionDetails = styled.div``;

export const SubsTop = styled.div`
  background-color: ${({ active }) => (active ? '#f9fff2' : '#ffffff')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.5rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .bundle-info {
    .bundle-name {
      font-weight: 700;
      color: #000000;
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .bundle-period {
      color: #606060;
      font-size: 0.6875rem;
    }
  }
  .renew-btn {
    color: #eb5400;
    background: #ffffff;
    border: 1px solid #eb5400;
    font-size: 0.875rem;
    outline: none;
    width: 134px;
    height: 34px;
    border-radius: 2px;
    &:hover {
      background: #fffaf7;
    }
  }
`;

export const StatusLabel = styled.span`
  color: ${({ active }) => (active ? '#59A21B' : 'rgba(255, 0, 0, 0.75)')};
  background: ${({ active }) =>
    active ? 'rgba(230, 255, 201, 0.46)' : 'none'};
  font-size: 0.75rem;
  border-radius: 11px;
  font-weight: 500;
  padding: 0.2rem 0.7rem;
  text-align: center;
`;

export const SubjectPlanLabel = styled.span`
  background: #ffeea5;
  border-radius: 4px;
  font-size: 10px;
  font-style: italic;
  font-weight: 700;
  font-family: 'Helvetica Neue';
  padding: 0.2rem 0.3rem;
`;

export const SubsBottom = styled.div`
  padding: 1.2rem 1.5rem;
  background: ${({ active }) => (active ? '#efffdd' : '#FAFAFA')};
  font-size: 0.6875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .bundle-price {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    .renewal-rate {
      color: #000000;
    }
    .payment-details {
      color: #606060;
    }
    .period-date {
      color: #fc5b00;
    }
  }
  .cancel-btn {
    border: none;
    background: none;
    color: #000000;
    text-decoration: underline;
  }
`;

export const OfferContainer = styled.div`
  img {
    width: 100%;
  }
`;

export const EarlyRenewModal = styled.div`
  background: #ffffff;
  border-radius: 12px;
  font-size: 1rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  a {
    color: #000000;
    text-decoration: underline;
    font-weight: 600;
  }
  button {
    color: #ffffff;
    background: #ff5c00;
    border: none;
    outline: none;
    border-radius: 2px;
    padding: 0.5rem 3.87rem;
  }
`;
