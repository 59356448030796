import { getCurrentDateTimeInTimezone } from 'pages/Events/EventList';

// Checks if current user's time in their local timezone is within 6 am to 10 pm
const checkIsOpen = (timezone) => {
  if (!timezone) {
    return false;
  }
  const currentDateTime = getCurrentDateTimeInTimezone(timezone);
  const startTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 6,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const endTime = getCurrentDateTimeInTimezone(timezone).set({
    hour: 22,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return currentDateTime.isBetween(startTime, endTime, undefined, '[]');
};

export default checkIsOpen;
