import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.p`
  margin-bottom: 0;
  color: #ef3d4c;
  font-size: 1rem;
  text-align: center;
`;

export const Timer = styled.p`
  display: flex;
  gap: 0.5rem;
`;

export const TimeUnits = styled.span``;

export const Number = styled.span`
  font-weight: 700;
  font-size: 1.5rem;
  color: #fe5a00;
`;
