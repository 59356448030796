import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';

import { ReactComponent as Premium } from 'assets/img/plan-toggle/icon-premium.svg';

import { setCurrentProduct } from 'store/plan/planSlice';

import Header from 'components/Header';
import { Button, BackButton } from 'components/Shared';
import { trialContentConfig } from './constants';
import { OTPModal } from '../components';

import { StyledButtonContainer } from '../index.styles';
import { Page, StyledContainer } from './OTPTrial.styles';

const OTPTrial = () => {
  const { t } = useTranslation([
    'scienceTrial',
    'mathTrial',
    'englishTrial',
    'bundleTrial',
    'common',
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { subjectName } = useParams();
  const [showModal, setShowModal] = useState(false);
  const {
    prevProduct,
    currentProduct,
    isLoading: planIsLoading,
    products,
  } = useSelector((state) => state.plan);
  const { isLoading } = useSelector((state) => state.studentDetails);

  const lowerSubjectName = subjectName?.toLowerCase();
  const allLoading = isLoading || planIsLoading;

  const backHandler = () => {
    if (!isNil(prevProduct)) {
      dispatch(setCurrentProduct(prevProduct));
      history.push(`/dashboard`);
      return;
    }
    if (currentProduct && currentProduct.expired) {
      history.push(`/trial/${currentProduct.subject.toLowerCase().trim()}`);
      return;
    }
    history.push(`/dashboard`);
  };

  return (
    !allLoading &&
    products && (
      <Page subject={lowerSubjectName}>
        <Header toggleMenu={false} toggleAccessMenu={false} />
        <StyledButtonContainer>
          <BackButton
            btnBgColor={trialContentConfig[lowerSubjectName].backBtnColor}
            padding="0.4rem 1rem"
            backText={t('common:back', 'Back')}
            onClick={backHandler}
          />
        </StyledButtonContainer>
        <StyledContainer subject={lowerSubjectName}>
          <div className="desc">
            {trialContentConfig[lowerSubjectName].showPremiumIcon && (
              <Premium width="76px" height="23px" />
            )}
            {trialContentConfig[lowerSubjectName].showNewBadge && (
              <div className="new">{t('common:new', 'NEW!')}</div>
            )}
            <h3 className="main-title">
              {t(
                `${trialContentConfig[lowerSubjectName].translationKey}:productTitle`,
                `${trialContentConfig[lowerSubjectName].title}`
              )}
            </h3>
            <h5 className="main-desc">
              {t(
                `${trialContentConfig[lowerSubjectName].translationKey}:productDesc`,
                `${trialContentConfig[lowerSubjectName].desc}`
              )}
            </h5>
            {trialContentConfig[lowerSubjectName].showBoostBadge && (
              <div className="boost">
                {t(
                  `${trialContentConfig[lowerSubjectName].translationKey}:productSecondDesc`,
                  `${trialContentConfig[lowerSubjectName].secondDesc}`
                )}
              </div>
            )}
            <Button
              dataCy="try-button"
              variant="primary"
              fontWeight="700"
              fontSize="1.5rem"
              padding="0.7rem 3.5rem"
              onClick={() => setShowModal(true)}
            >
              {t('bundleTrial:try', 'Try Now')}
            </Button>
          </div>
          {trialContentConfig[lowerSubjectName].videoUrl ? (
            <iframe
              width="560"
              height="315"
              src={trialContentConfig[lowerSubjectName].videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <img
              className="cover"
              src={trialContentConfig[lowerSubjectName].coverImage}
              alt="cover"
            />
          )}
        </StyledContainer>
        <OTPModal showModal={showModal} setShowModal={setShowModal} />
      </Page>
    )
  );
};

export default OTPTrial;
