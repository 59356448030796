import styled from 'styled-components';

export const Page = styled.div`
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ::after {
    content: '';
    display: block;
    position: absolute;
    background-color: #fefdf0;
    bottom: 0;
    height: 38%;
    left: 0;
    right: 0;
    z-index: -1;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 2rem 0 2rem;
  gap: 2.25rem;
  position: relative;
  bottom: 5rem;
  svg {
    margin-bottom: 12px;
  }
  .desc {
    max-width: 390px;
    display: flex;
    flex-direction: column;
    .new {
      width: fit-content;
      background: #ffc619;
      color: #846600;
      font-family: 'Muli';
      font-size: 17px;
      font-weight: 900;
      padding: 7px 14px;
      border-radius: 8px;
      display: inline-block;
      margin-bottom: 4px;
      line-height: 1;
    }
    .main-title {
      font-weight: 900;
      font-family: 'Muli';
      font-size: 26px;
    }
    .main-desc {
      line-height: 28px;
      font-size: 1rem;
      margin-bottom: 1rem;
      p {
        margin-bottom: 0;
      }
      .highlight {
        background: #ffff00;
        width: fit-content;
      }
    }
  }
  .action-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
`;
