export const SUBS_DISPLAY_STATUS = {
  0: 'expired',
  1: 'active',
};

export const SUBS_TYPE = {
  1: 'trial',
  2: 'paid',
};

export const BUNDLE_VERSION = {
  1: 'v1',
  2: 'v2',
};

export const PAYMENT_PROVIDER = {
  1: 'stripe',
  2: 'paypal',
  3: 'otpTrial',
  4: 'localPayment',
};

export const BUNDLE_TYPE_SUBJECT_COUNT = {
  11: '1',
  12: '2',
  13: '3',
  14: '4',
};

export const DURATION_TYPE = {
  1: 'yearly',
  2: 'monthly',
};

// Deprecated reasons are set to isActive = false
export const TRIAL_CANCEL_REASONS = [
  {
    id: 1,
    option: 'My child doesn’t enjoy using KooBits.',
    isActive: false,
  },
  {
    id: 2,
    option:
      'It’s not challenging enough for my child / It’s too challenging for my child.',
    isActive: false,
  },
  {
    id: 3,
    option: 'It’s too expensive for me.',
    isActive: false,
  },
  {
    id: 4,
    option: 'Others',
    isActive: true,
    displayOrder: 10,
  },
  {
    id: 5,
    option: 'My child already has a school account.',
    isActive: true,
    displayOrder: 1,
  },
  {
    id: 6,
    option: 'I encountered technical issues.',
    isActive: true,
    displayOrder: 2,
  },
  {
    id: 7,
    option: 'I prefer a monthly subscription.',
    isActive: true,
    displayOrder: 3,
  },
  {
    id: 8,
    option: 'I find the questions too challenging for my child.',
    isActive: true,
    displayOrder: 4,
  },
  {
    id: 9,
    option: 'I find the questions too easy for my child.',
    isActive: true,
    displayOrder: 5,
  },
  {
    id: 10,
    option: 'The syllabus is not relevant to my child.',
    isActive: true,
    displayOrder: 6,
  },
  {
    id: 11,
    option: 'I didn’t have time to explore all the features during the trial.',
    isActive: true,
    displayOrder: 7,
  },
  {
    id: 12,
    option: 'I wasn’t sure how to use certain features.',
    isActive: true,
    displayOrder: 8,
  },
  {
    id: 13,
    option: 'I couldn’t find certain features (videos, questions, games, etc)',
    isActive: true,
    displayOrder: 9,
  },
];
export const PAID_CANCEL_REASONS = [
  {
    id: 14,
    option: 'My child has a school account',
    isActive: true,
    displayOrder: 2,
  },
  {
    id: 15,
    option: 'I don’t want my card to be on Auto-renew',
    isActive: true,
    displayOrder: 1,
  },
  {
    id: 16,
    option: 'My child graduated',
    isActive: true,
    displayOrder: 3,
  },
  {
    id: 17,
    option: 'My child did not improve',
    isActive: true,
    displayOrder: 4,
  },
  {
    id: 18,
    option: 'My child was not motivated to use',
    isActive: true,
    displayOrder: 5,
  },
  {
    id: 19,
    option: 'Difficult to use',
    isActive: true,
    displayOrder: 6,
  },
  {
    id: 20,
    option: 'Too expensive',
    isActive: true,
    displayOrder: 7,
  },
  {
    id: 21,
    option: 'Others',
    isActive: true,
    displayOrder: 8,
  },
];
