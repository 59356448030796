import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import {
  getStudentDetails,
  setShowPremiumSchoolToggleModal,
} from 'store/dashboard/studentDetailsSlice';
import { getCourses } from 'store/kooClass/kooClassSlice';
import { setCurrentProduct } from 'store/plan/planSlice';

import useIsMobile from 'hooks/responsive/useIsMobile';
import useResponsiveValue from 'hooks/responsive/useResponsiveValue';

import { featureToggle } from 'constants/index';
import { PRODUCT_TYPE } from 'constants/products';

import { ReactComponent as Logo } from 'assets/img/logo.svg';

import { AutoFadeoutLoader, ModalContentXButton } from 'components/Shared';
import { NavProfileMobile, NavProfile, NavbarCollapse } from './Navigations';
import ToggleV2 from './ToggleV2';
import TogglePlanModalContent from './TogglePlanModalContent';
import { HeaderNav } from './Header.styles';

const Header = ({
  hidePlan,
  toggleMenu = true,
  toggleProfile = true,
  parentPortal,
  toggleAccessMenu = true,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { studentDetails, isLoading, showPremiumSchoolToggleModal } =
    useSelector((state) => state.studentDetails);
  const { isParentAccount } = useSelector((state) => state.login);
  const { courses, isErr } = useSelector((state) => state.kooClass);
  const { plan, currentProduct, prevProduct } = useSelector(
    (state) => state.plan
  );
  const isSchool = plan === PRODUCT_TYPE.SCHOOL;
  // COMPONENT STATES
  const [retryCount, setRetryCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [hasSeenLoader, setHasSeenLoader] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState(null);

  // Responsive
  const isMobile = useIsMobile();
  const premiumToggleModalWidth = useResponsiveValue({
    mobile: '200px',
    tablet: '388px',
    desktop: '388px',
  });

  // Event Handlers

  const logoClickHandler = () => {
    if (currentProduct && !currentProduct.expired) {
      history.push(`/dashboard`);
      return;
    }
    if (!isNil(prevProduct) && !prevProduct.expired) {
      dispatch(setCurrentProduct(prevProduct));
      history.push(`/dashboard`);
    }
  };
  // USEEFFECTS
  useEffect(() => {
    // Retry student details
    if (
      isParentAccount === false &&
      studentDetails === null &&
      retryCount < 3 &&
      !isLoading
    ) {
      setRetryCount((prev) => {
        return prev + 1;
      });
      dispatch(getStudentDetails());
    }
  }, [dispatch, isLoading, isParentAccount, retryCount, studentDetails]);

  useEffect(() => {
    if (isParentAccount === false && isNil(courses) && isErr === null) {
      dispatch(getCourses());
    }
  }, [courses, dispatch, isErr, isParentAccount]);
  return (
    <HeaderNav plan={plan} isParentAccount={isParentAccount}>
      {!hasSeenLoader && (
        <AutoFadeoutLoader
          showLoader={showLoader}
          setHasSeen={setHasSeenLoader}
          setShowLoader={setShowLoader}
          redirectUrl={redirectUrl}
        />
      )}
      <ModalContentXButton
        showModal={showPremiumSchoolToggleModal}
        closeModal={() => {
          dispatch(setShowPremiumSchoolToggleModal(false));
        }}
        backdrop="static"
        containerMinWidth={premiumToggleModalWidth}
      >
        <TogglePlanModalContent
          setShowLoader={setShowLoader}
          setHasSeen={setHasSeenLoader}
        />
      </ModalContentXButton>
      <div className="navbar-brand">
        <Logo className="logo" onClick={logoClickHandler} />
        {featureToggle.science === true && hidePlan !== true && (
          <ToggleV2
            setShowLoader={setShowLoader}
            setHasSeenLoader={setHasSeenLoader}
            setRedirectUrl={setRedirectUrl}
            showLoader={showLoader}
            hasSeenLoader={hasSeenLoader}
          />
        )}
        {parentPortal && <span className="parent-portal">Parent</span>}
      </div>
      {((toggleMenu && !isMobile) || parentPortal) && <NavbarCollapse />}
      {isSchool
        ? toggleProfile && (
            <NavProfile
              parentPortal={parentPortal}
              toggleAccessMenu={toggleAccessMenu}
            />
          )
        : ((toggleProfile && !isMobile) || parentPortal) && (
            <NavProfile
              parentPortal={parentPortal}
              toggleAccessMenu={toggleAccessMenu}
            />
          )}
      {!isSchool && toggleProfile && isMobile && !parentPortal && (
        <NavProfileMobile parentPortal={parentPortal} />
      )}
    </HeaderNav>
  );
};

export default Header;
