import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isNil } from 'ramda';

import { Container, Title, Timer, Number, TimeUnits } from './CountDown.styles';

const CountDown = () => {
  const { t } = useTranslation(['englishTrial', 'common']);
  const [timeLeft, setTimeLeft] = useState(null);

  const now = moment();

  // 15th oct 2024 month
  const midOfOct = moment([2024, 9, 15]).endOf('day');
  // End of the current month
  const endOfMonth = moment().endOf('month').endOf('day');

  const deadline = now.isAfter(midOfOct) ? endOfMonth : midOfOct;

  const calculateTimeLeft = () => {
    const current = moment();
    const timeDiff = deadline.diff(current);

    const duration = moment.duration(timeDiff);
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    setTimeLeft({ days, hours, minutes, seconds });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isNil(timeLeft)) return null;

  return (
    <Container>
      <Title>
        {t(
          'englishTrial:buyEnglish.countDown',
          {
            deadline: deadline.format('DD MMMM'),
          },
          `Limited-Time Offer Till ${deadline.format('DD MMMM')} Only!`
        )}
      </Title>
      <Timer>
        <span>
          <Number>{`${timeLeft.days} `}</Number>
          <TimeUnits>{t('common:date.day', 'Days')}</TimeUnits>
        </span>
        <span>
          <Number>{`${timeLeft.hours} `}</Number>
          <TimeUnits>{t('common:date.hour', 'Hours')}</TimeUnits>
        </span>
        <span>
          <Number>{`${timeLeft.minutes} `}</Number>
          <TimeUnits>{t('common:date.minute', 'M')}</TimeUnits>
        </span>
        <span>
          <Number>{`${timeLeft.seconds} `}</Number>
          <TimeUnits>{t('common:date.second', 'S')}</TimeUnits>
        </span>
      </Timer>
    </Container>
  );
};

export default CountDown;
