import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { sidePanelNavigation, subNavData } from 'constants/dashboardv2';

import PremiumIcon from 'assets/img/dashboardv2/premium.svg';
import { ReactComponent as ArrowIcon } from 'assets/img/dashboardv2/dashboard/arrow-icon.svg';

import { rwd } from 'Theme';
import {
  NavList,
  NavItem,
  NavContnet,
  NavItemTop,
  NavItemBottom,
  SubNavList,
  SubNavItem,
  PremiumSmallIcon,
} from './SubpageNav.style';

export const useKooClassEnabledStatus = () => {
  const { currentProduct } = useSelector((state) => state.plan);
  const currentProductKooClassConfig = currentProduct?.features.find(
    (item) => item.feature.toLowerCase() === 'kooclass'
  );
  const kooclassLink =
    currentProduct && currentProductKooClassConfig
      ? `/dashboard?view=kooclass&courseid=${currentProductKooClassConfig?.parameters?.CourseId}`
      : '/';
  const kooClassIsEnabled =
    currentProductKooClassConfig?.parameters?.IsEnabled ?? false;
  return {
    kooclassLink,
    kooClassIsEnabled,
  };
};

const MainNavContent = ({
  activeView,
  subject,
  view,
  t,
  index,
  kooClassIsEnabled,
}) => {
  const isShowPremiumIcon = !kooClassIsEnabled && index === 2;
  return (
    <>
      <NavContnet active={activeView === view.id} subject={subject}>
        <view.icon />
        {t(`dashboard:sidepanel.${view.name}`, view.name)}
      </NavContnet>
      {isShowPremiumIcon && <PremiumSmallIcon src={PremiumIcon} />}
      {activeView === view.id && (
        <>
          <NavItemTop subject={subject} />
          <NavItemBottom subject={subject} />
          <ArrowIcon className="current-arrow" />
        </>
      )}
    </>
  );
};

const useGetMenuList = () => {
  const isMobile = useMediaQuery({ maxWidth: rwd.mobile });
  if (isMobile) {
    return sidePanelNavigation.slice(0, 2);
  }
  return sidePanelNavigation;
};

const SubpageNav = ({ activeView }) => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();
  const { subject } = useSelector((state) => state.plan);
  const { kooclassLink, kooClassIsEnabled } = useKooClassEnabledStatus();
  const list = useGetMenuList();
  const navigateToKooClassView = (route) => {
    return () => {
      history.push(route);
    };
  };
  return (
    <>
      <NavList data-cy="sidepanel-navigation">
        {list.map((view, index) => {
          const isActive = activeView === view.id ? 1 : 0;
          return (
            <NavItem
              data-cy="sidepanel-navigation-item"
              key={view.id}
              onClick={navigateToKooClassView(
                kooClassIsEnabled && view.id === 3
                  ? kooclassLink
                  : `/dashboard?view=${view.name}`
              )}
              active={isActive}
              subject={subject}
            >
              <MainNavContent
                index={index}
                kooClassIsEnabled={kooClassIsEnabled}
                activeView={activeView}
                subject={subject}
                t={t}
                view={view}
              />
            </NavItem>
          );
        })}
      </NavList>
      <SubNavList subject={subject} data-cy="sidepanel-sub-navigation">
        {subNavData.map((sub) => {
          const Icon = sub.icon;
          return (
            <SubNavItem
              key={sub.id}
              to={sub.link}
              subject={subject}
              data-cy="sidepanel-sub-navigation-item"
            >
              <div className="circle">
                <Icon />
              </div>
              <div className="label">
                {t(`dashboard:sidepanel.${sub.name}`, sub.name)}
              </div>
            </SubNavItem>
          );
        })}
      </SubNavList>
    </>
  );
};

export default SubpageNav;
