import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { betweenOpenHour, upcomingSMCHour } from 'helpers/eventHour';
import moment from 'moment-timezone';
import Topbar from 'components/Events/Topbar';
import EventCard from 'components/Events/EventsCard';
import EventStatusText from 'components/Events/EventStatusText';
import Spinner from 'components/Shared/Spinner';
import { eventSubmissionStatus, featureToggle } from 'constants/index';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { getEventsList } from 'store/event/eventsListSlice';
import { Container, List, ErrorText } from './EventList.styles';

export const getCurrentDateTimeInTimezone = (timezone) => {
  const currentDateTime = moment();
  return currentDateTime.clone().tz(timezone);
};

const EventList = () => {
  const dispatch = useDispatch();
  const { eventsList, isLoading, error } = useSelector(
    (state) => state.eventsList
  );
  const { timezone } = useSelector((state) => state.login);
  const { studentDetails, isLoading: isStudentDetailsLoading } = useSelector(
    (state) => state.studentDetails
  );
  const { allNonExpiredSubscriptionProducts } = useSelector(
    (state) => state.plan
  );
  const isMobile = useIsMobile();

  // Check the account is registered through localized route
  const isLocalizedUser =
    (studentDetails && studentDetails.Roles.includes(107)) ?? false;
  // const isLocalizedUser =
  //   (studentDetails && studentDetails.IsLocalizedUser) ?? false;
  const countryId =
    allNonExpiredSubscriptionProducts?.[0]?.subscriptionDetails?.CountryId;
  const isIDUser = countryId === 100;
  const isHKUser = countryId === 96;
  // Based on user's localised country
  const hasSMC = !isIDUser && !isHKUser;

  // dates
  const [today, setToday] = useState(
    getCurrentDateTimeInTimezone(timezone).format()
  );
  const dateChange = () => {
    setToday(
      moment
        .tz(
          (document.getElementById('currentDates') || {}).value || 'nodate',
          timezone
        )
        .format()
    );
  };
  useEffect(() => {
    if (
      (!isLocalizedUser || (isLocalizedUser && hasSMC)) &&
      !isStudentDetailsLoading
    ) {
      dispatch(getEventsList());
    }
  }, [
    dispatch,
    hasSMC,
    isLocalizedUser,
    isStudentDetailsLoading,
    studentDetails,
  ]);

  const eventStatus = (event) => {
    const startDate = moment.tz(event.EventStartDate, timezone).format();
    const endDate = moment.tz(event.EventEndDate, timezone).format();
    const resultDate = moment.tz(event.TestResultDate, timezone).format();
    const stillOpen = betweenOpenHour(today, startDate, endDate, timezone);
    if (upcomingSMCHour(today, startDate, timezone)) {
      return 'upcoming';
    }
    if (
      eventSubmissionStatus[event.TestSubmissionStatus] !== 'Submitted' &&
      eventSubmissionStatus[event.TestSubmissionStatus] !== 'Claimed' &&
      !stillOpen
    ) {
      return 'expired';
    }
    if (
      stillOpen &&
      eventSubmissionStatus[event.TestSubmissionStatus] !== 'Submitted'
    ) {
      return 'open';
    }
    if (
      eventSubmissionStatus[event.TestSubmissionStatus] === 'Submitted' &&
      today >= resultDate
    ) {
      return 'result';
    }
    if (
      eventSubmissionStatus[event.TestSubmissionStatus] === 'Submitted' &&
      today <= resultDate
    ) {
      return 'submitted';
    }
    if (event.TestTotalCPPointsClaimed !== null) {
      return 'claimed';
    }
  };
  return (
    <Container>
      <Topbar />
      {featureToggle.smcDatePicker && (
        <>
          <label htmlFor="currentDates">Date:</label>
          <input
            type="datetime-local"
            id="currentDates"
            name="currentDates"
            onChange={dateChange}
          />
        </>
      )}
      <List>
        {isLoading && <Spinner />}
        {!isLoading &&
          error === null &&
          (isLocalizedUser && !hasSMC ? (
            <ErrorText>
              <h4>Events not available</h4>
            </ErrorText>
          ) : (
            eventsList.map((event) => (
              <div key={event.EventId}>
                {isMobile &&
                  (eventStatus(event) === 'open' ||
                    eventStatus(event) === 'result' ||
                    eventStatus(event) === 'submitted') && (
                    <EventStatusText
                      status={eventStatus(event)}
                      endTime={event.EventEndDate}
                    />
                  )}
                <EventCard
                  key={event.EventId}
                  id={event.EventId}
                  start={event.EventStartDate}
                  endTime={event.EventEndDate}
                  totalQn={event.SMCQuestionNo}
                  eventStatus={eventStatus(event)}
                  cp={
                    event.TestTotalCPPointsClaimed !== null
                      ? event.TestTotalCPPointsClaimed
                      : 0
                  }
                  title={event.EventName}
                  submissionId={event.TestSubmissionId}
                />
              </div>
            ))
          ))}
        {!isLoading && error !== null && (
          <ErrorText>
            <h3>Error</h3>
            <h4>{error}</h4>
          </ErrorText>
        )}
      </List>
    </Container>
  );
};

export default EventList;
