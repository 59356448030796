export const SELLING_POINT = [
  {
    key: 'point1',
    isSgOnly: false,
    text: '● Animated Vocab Cards✨',
  },
  {
    key: 'point2',
    isSgOnly: false,
    text: '● Curriculum-aligned stories📚',
  },
  {
    key: 'point3',
    isSgOnly: false,
    text: '● English BrainGames🎮',
  },
  {
    key: 'point4',
    isSgOnly: false,
    text: '● Earn More KoKo Credits and Rewards🏆',
  },
  {
    key: 'point5',
    isSgOnly: true,
    text: '● 🦊Limited Edition Miko Plushie Gift Box!🦊',
  },
];
