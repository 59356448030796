import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { updateUserPreferences } from 'store/userPreferences/userPreferencesSlice';
import { resetNotification } from 'store/notification/notificationSlice';

import { setCurrentProduct } from 'store/plan/planSlice';

import { ReactComponent as School } from 'assets/img/plan-toggle/icon-school.svg';
import { ReactComponent as Premium } from 'assets/img/plan-toggle/icon-premium.svg';

import Button from 'components/Shared/Button';

import { PRODUCT_TYPE } from 'constants/products';
import { Container, Action } from './SwitchModalV2.styles';

const SwitchModalV2 = ({
  setModal,
  setShowLoader,
  setHasSeenLoader,
  selectedProduct,
}) => {
  const { t } = useTranslation(['common', 'switchPlan']);
  const dispatch = useDispatch();
  const history = useHistory();
  const confirmHandler = () => {
    setModal(false);
    setShowLoader(true);
    setHasSeenLoader(false);
    dispatch(resetNotification());
    dispatch(setCurrentProduct(selectedProduct));
    dispatch(updateUserPreferences(selectedProduct.productId));
    history.push(`/dashboard`);
  };
  const plan = selectedProduct?.productType.toLowerCase().trim();
  return (
    <Container>
      <p className="desc">
        {t('switchPlan:switchV2.title', 'You are going to switch to')}
      </p>
      <div className="objective">
        {plan === PRODUCT_TYPE.SCHOOL ? <School /> : <Premium />}
        {plan !== PRODUCT_TYPE.SCHOOL && (
          <span>
            {t(
              `common:subjects.${selectedProduct?.subject}`,
              selectedProduct.subject
            )}
          </span>
        )}
      </div>
      <Action>
        <Button
          variant="secondary"
          padding="0.625rem 2.31rem"
          fontSize="1rem"
          onClick={() => setModal(false)}
        >
          {t('common:cancel', 'Cancel')}
        </Button>
        <Button
          variant="orange4"
          padding="0.625rem 2.31rem"
          fontSize="1rem"
          onClick={confirmHandler}
        >
          {t('common:confirm', 'Confirm')}
        </Button>
      </Action>
    </Container>
  );
};

export default SwitchModalV2;
