import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import ScienceGiftBoxImg from 'assets/img/dailychallenge/mystery-koko/giftbox.png';
import { ChallengeLevelMysteryChests } from 'constants/dailyChallenge';
import { subjectIDs, SUBJECTS } from 'constants/products';

const useDCMysteryKoko = ({ subjectID, challengeLevel }) => {
  const hasMysteryKoko = false;
  const { products } = useSelector((state) => state.plan);
  const currentDCSubjectName = subjectIDs[subjectID];
  const currentSubjectProduct = products.find(
    (product) =>
      product.subject.toLowerCase().trim() ===
      currentDCSubjectName?.toLowerCase().trim()
  );
  if (isNil(currentSubjectProduct)) {
    return {
      hasMysteryKoko,
    };
  }
  const userHasB2CPlan = !isNil(
    currentSubjectProduct.nonExpiredSubscriptionProducts.find(
      (sub) => sub.productType.toLowerCase().trim() !== 'school'
    )
  );
  if (!userHasB2CPlan) {
    return {
      hasMysteryKoko,
    };
  }

  if (
    currentDCSubjectName === SUBJECTS.MATH &&
    Object.keys(ChallengeLevelMysteryChests)
      .map((level) => parseInt(level, 10))
      .includes(challengeLevel)
  ) {
    return {
      hasMysteryKoko: true,
      boxImg: ChallengeLevelMysteryChests[challengeLevel],
    };
  }
  if (currentDCSubjectName) {
    return {
      hasMysteryKoko: true,
      boxImg: ScienceGiftBoxImg,
    };
  }
  return {
    hasMysteryKoko: false,
  };
};

export default useDCMysteryKoko;
