import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import loginReducer from 'store/login/loginSlice';
import planReducer from 'store/plan/planSlice';
import rewardsReducer from 'store/dashboard/rewardsSlice';
import studentDetailsReducer from 'store/dashboard/studentDetailsSlice';
import assignmentListReducer from 'store/assignment/assignmentListSlice';
import assignmentReducer from 'store/assignment/assignmentSlice';
import assignmentParentReducer from 'store/assignment/assignmentParentSlice';
import barModelReducer from 'store/assignment/barModelSlice';
import assistToolsReducer from 'store/assignment/assistToolsSlice';
import eventReducer from 'store/event/eventSlice';
import storyReducer from 'store/story/storySlice';
import avatarCreatorReducer from 'store/avatar/avatarCreatorSlice';
import avatarShowcaseReducer from 'store/avatar/avatarShowcaseSlice';
import bookClubReducer from 'store/story/bookClubSlice';
import timerReducer from 'store/timer/timerSlice';
import eventsListReducer from 'store/event/eventsListSlice';
import missionReducer from 'store/mission/missionSlice';
import multiplayerListReducer from 'store/multiplayer/multiplayerListSlice';
import multiplayerReducer from 'store/multiplayer/multiplayerSlice';
import dailyChallengeReducer from 'store/dailyChallenge/dailyChallengeSlice';
import randomWordsReducer from 'store/dailyChallenge/randomWordsSlice';
import superSpeedReducer from 'store/superSpeed/superSpeedSlice';
import superVisionReducer from 'store/superVision/superVisionSlice';
import superheroChallengeReducer from 'store/superheroChallenge/superheroChallengeSlice';
import LeaderboardReducer from 'store/leaderboard/leaderboardSlice';
import TopBrainReducer from 'store/topBrain/topBrainSlice';
import championClassReducer from 'store/championClass/championClassSlice';
import hodCalendarReducer from 'store/dailyChallenge/hodCalendarSlice';
import friendsReducer from 'store/friends/friendsSlice';
import reportReducer from 'store/report/reportSlice';
import bffReducer from 'store/bff/bffSlice';
import lionCityReducer from 'store/lionCity/lionCitySlice';
import wizardSchoolReducer from 'store/wizardSchool/wizardSchoolSlice';
import zodiacRaceReducer from 'store/zodiacRace/zodiacRaceSlice';
import boysVsGirlsReducer from 'store/boysVsGirls/boysVsGirlsSlice';
import superheroLeaderboardReducer from 'store/superheroLeaderboard/superheroLeaderboardSlice';
import brainGameReducer from 'store/brainGame/brainGameSlice';
import userPreferencesReducer from 'store/userPreferences/userPreferencesSlice';
import lookupReducer from 'store/lookup/lookupSlice';
import profileSettingsReducer from 'store/profileSettings/profileSettingsSlice';
import kooQuizReducer from 'store/kooQuiz/kooQuizSlice';
import parentSettingsReducer from 'store/parent/parentSettingsSlice';
import kooClassReducer from 'store/kooClass/kooClassSlice';
import factualFluencyLeaderboardReducer from 'store/factualFluencyLeaderboard/factualFluencyLeaderboardSlice';
import notificationReducer from 'store/notification/notificationSlice';
import trialSignupReducer from 'store/trialSignup/trialSignupSlice';
import curriculumReducer from 'store/curriculum/curriculumSlice';
import kokoHistorySliceReducer from 'store/kokoHistory/kokoHistorySlice';
import cpHistoryReducer from 'store/cpHistory/cpHistorySlice';
import kooChallengeReducer from 'store/dashboard/kooChallengeSlice';
import cpsDetailSlice from 'store/dailyChallenge/cpsDetailSlice';
import voiceoverSlice from 'store/voiceover/voiceoverSlice';
import hodLeaderboardReducer from 'store/leaderboard/hodLeaderboardSlice';
import topBrainLeaderboardReducer from 'store/leaderboard/topBrainLeaderboardSlice';
import superHeroLeaderBoardReducer from 'store/leaderboard/superHeroLeaderBoardSlice';
import championClassLeaderboardReducer from 'store/leaderboard/championClassLeaderboardSlice';
import BFFReducer from 'store/leaderboard/bffLeaderboardSlice';
import drawingKitsReducer from 'store/assignment/drawingKitsSlice';

const middleware = [
  /* YOUR CUSTOM MIDDLEWARES HERE */
  ...getDefaultMiddleware(),
];

const combinedReducer = combineReducers({
  login: loginReducer,
  plan: planReducer,
  rewards: rewardsReducer,
  studentDetails: studentDetailsReducer,
  assignmentList: assignmentListReducer,
  assignment: assignmentReducer,
  assignmentParent: assignmentParentReducer,
  barModel: barModelReducer,
  assistTools: assistToolsReducer,
  event: eventReducer,
  timer: timerReducer,
  eventsList: eventsListReducer,
  story: storyReducer,
  avatarCreator: avatarCreatorReducer,
  avatarShowcase: avatarShowcaseReducer,
  mission: missionReducer,
  multiplayerList: multiplayerListReducer,
  multiplayer: multiplayerReducer,
  bookclub: bookClubReducer,
  dailyChallenge: dailyChallengeReducer,
  randomWords: randomWordsReducer,
  superSpeed: superSpeedReducer,
  superVision: superVisionReducer,
  superheroChallenge: superheroChallengeReducer,
  leaderboard: LeaderboardReducer,
  topBrain: TopBrainReducer,
  championClass: championClassReducer,
  hodCalendar: hodCalendarReducer,
  friends: friendsReducer,
  report: reportReducer,
  bff: bffReducer,
  lionCity: lionCityReducer,
  wizardSchool: wizardSchoolReducer,
  zodiacRace: zodiacRaceReducer,
  boysVsGirls: boysVsGirlsReducer,
  superheroLeaderboard: superheroLeaderboardReducer,
  brainGame: brainGameReducer,
  userPreferences: userPreferencesReducer,
  lookup: lookupReducer,
  profileSettings: profileSettingsReducer,
  kooQuiz: kooQuizReducer,
  parentSettings: parentSettingsReducer,
  kooClass: kooClassReducer,
  notification: notificationReducer,
  factualFluencyLeaderboard: factualFluencyLeaderboardReducer,
  trialSignup: trialSignupReducer,
  curriculum: curriculumReducer,
  kokoHistory: kokoHistorySliceReducer,
  cpHistory: cpHistoryReducer,
  kooChallenge: kooChallengeReducer,
  cpsDetail: cpsDetailSlice,
  voiceover: voiceoverSlice,
  hodV2: hodLeaderboardReducer,
  topBrainV2: topBrainLeaderboardReducer,
  superHeroLeaderBoardV2: superHeroLeaderBoardReducer,
  championClassV2: championClassLeaderboardReducer,
  bffV2: BFFReducer,
  drawingKits: drawingKitsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'reset-store') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
  enhancers: [],
  // devTools: process.env.NODE_ENV !== 'production',
});

export default store;
